@import 'custom/variables';
@import 'react-toastify/scss/main';
@import 'bootstrap/scss/bootstrap';
@import 'custom/custom';
@import '~react-super-responsive-table/dist/SuperResponsiveTableStyle.css';

svg {
  margin-bottom: 3px;
}

.loader-wrapper {
  position: relative;
  .loader {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, .7);
    z-index: 9;
  }
}

.autocomplete-wrapper {
  position: relative;
  .data-list {
    position: absolute;
    top: 35px;
    list-style: none;
    padding: 0;
    margin: 0;
    background-color: #ffffff;
    width: 100%;
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
    z-index: 1;
    li {
      border-bottom: 1px solid #e9ecef;
      border-right: 1px solid #e9ecef;
      border-left: 1px solid #e9ecef;
      button {
        background: transparent;
        border: none;
        color: #3e5569;
        width: 100%;
        padding: 0;
        text-align: left;
        padding: 5px 10px;
        &:hover {
          color: #ffffff;
          background-color: $themecolor;
        }
      }
    }
  }
  .loading {
    position: absolute;
    right: 10px;
    top: 10px;
  }
}

.is-invalid .select__control {
  border-color: map-get($theme-colors, danger) !important;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23f62d51' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23f62d51' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 45px center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.select__ {
  &control {
    border-radius: 2px !important;
    color: #525f7f !important;
    background-color: #fff !important;
    background-clip: padding-box !important;
    border: 1px solid #e9ecef !important;
    min-height: 35px !important;
    height: 35px !important;
    box-shadow: none !important;

    &.select__control--is-focused {
      border-color: rgba(0, 0, 0, 0.25) !important;
      box-shadow: none;
    }
  }

  &indicator {
    padding: 0 8px !important;
  }

  &option {
    &.select__option--is {
      &-focused, &-selected {
        background-color: $themecolor !important;
        color: #ffffff !important;
      }
    }
  }
}

.custom-file-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

table.print {
  color: black;
  border-collapse: collapse;
  width: 100%;
  font-size: 40px;
  font-weight: bold;
  page-break-after: always;

  td {
    &.separator {
      border-top: 3px dashed;
    }

    &.double-separator {
      border-top: 3px dashed;
    }
  }
}

.page-item {
  a {
    cursor: pointer;
  }
}
